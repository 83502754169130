<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
        <table class="detailTable_search" style="width: 100%">
          <tr>
            <td class="titleTd width80px">日期</td>
          <td class="valueTd">
            <div class="block">
              <el-date-picker
                v-model="dataForm.time"
                type="daterange"
                align="right"
                unlink-panels

                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeHandler"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </td>
            <td class="titleTd width80px">区域</td>
              <td class="valueTd">
                <region-tree-jurisdiction :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域" v-model="dataForm.regionId" > </region-tree-jurisdiction>
          </td>
          <td class="titleTd width80px">异常类型</td>
              <td class="valueTd">
                <el-select v-model="dataForm.exceptionType" clearable placeholder="请选择" style="width:100%">
                  <el-option
                    v-for="item in tyoeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
          </td>
          <td class="titleTd width80px">处理状态</td>
              <td class="valueTd">
                <el-select v-model="dataForm.dealState" clearable placeholder="请选择" style="width:100%">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
          </td>
          </tr>
        </table>
      </el-form>
      <div class="wxts_msg_search" >
        <div class="f_t">
          <el-button-group  >
            <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">查询</el-button>
          </el-button-group>
        </div>
      </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        prop="pareaCode"
        header-align="center"
        align="center"
        label="区域">
        <template slot-scope="scope">
          <org :value="scope.row.pareaCode"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="exceptionType"
        header-align="center"
        align="center"
        label="异常类型">
        <template slot-scope="scope">
          <sysDict type="exception_type" :value="scope.row.exceptionType" />
        </template>
      </el-table-column>
      <el-table-column
        prop="expCount"
        header-align="center"
        align="center"
        label="网格异常数">
      </el-table-column>
      <el-table-column
        prop="dealState"
        header-align="center"
        align="center"
        label="处理状态">
        <template slot-scope="scope">
          <sysDict type="deal_state" :value="scope.row.dealState" />
        </template>
      </el-table-column>
      <el-table-column
        prop="exceptionDate"
        header-align="center"
        align="center"
        label="异常日期">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="detailsHandle(scope.row.id)">详情</el-button> 
          <el-button type="text" size="small" v-if="((isAuth('major:power:deal') || scope.row.type ==1)&&scope.row.expCount>0)"  @click="addOrUpdateHandle(scope.row.id,scope.row.dealState)">{{scope.row.bname}}</el-button>
          <el-button type="text" size="small" v-if="(isAuth('major:power:deal') && scope.row.dealState == '02' && dataForm.addType != 3 && dataForm.addType != 5 && scope.row.type != 3)" @click="transmitHandle(scope.row)">下达</el-button>
          <!-- <el-button type="text" size="small" v-if="scope.row.transmiUser != null && (scope.row.transmiUser != userId && scope.row.tramitState != '1' && scope.row.dealUser == null)" @click="eportedHandle(scope.row)">上报</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <Details v-if="detailsVisible" ref="details" @refreshDataList="getDataList"></Details>
    <transmit v-if="transmitVisible" ref="transmit" @refreshDataList="getDataList"></transmit>
    <report v-if="reportVisible" ref="report"  @refreshDataList="getDataList"></report>
  </div>
</template>

<script>
  import AddOrUpdate from './add-or-update'
  import Details from './details'
  import transmit from './transmit';
  import report from './report.vue';
  import {getList,getType } from "@/api/kanban/majorWarning";
  export default {
    data () {
      return {
        dataForm: {
          regionId:'',
          startTime:'',
          endTime:'',
          time:[],
          exceptionType:'',
          dealState:'01',
          addType:'',
        },
        deal:'',
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        detailsVisible:false,
        transmitVisible:false,
        reportVisible:false,
        options:[{
          value:'01',
          label:'待处理'
        },
        {
          value:'02',
          label:'已处理'
        },
        {
          value:'03',
          label:'已下达'
        },
      ],
      tyoeOptions:[
        {
          value:'01',
          label:'续费异常'
        },
        {
          value:'02',
          label:'流失异常'
        },
        {
          value:'03',
          label:'套餐异常'
        },
        {
          value:'04',
          label:'报停异常'
        },
        {
          value:'05',
          label:'新增异常'
        }
      ],
        pickerOptions: {
        shortcuts: [ {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      }
    },
    components: {
      AddOrUpdate,Details,transmit,report
    },
    activated () {
      this.dataForm.key =  this.$route.query.key;

      let pageIndex = this.$route.query.pageIndex;
      if(pageIndex) {
        this.pageIndex = parseInt(pageIndex);
      }

      let pageSize = this.$route.query.pageSize;
      if(pageSize) {
        this.pageSize = parseInt(pageSize);
      }

      this.getDataList();
    },
    computed: {
    regionId: {
            get() {
                const newregionId = this.$store.state.user.regionId;
                if (newregionId !== undefined) {
                    return newregionId;
                } else {
                    return ''
                }
            }
        },
        gridId: {
            get() {
                const newgridId = this.$store.state.user.gridId;
                if (newgridId !== undefined) {
                    return newgridId;
                } else {
                    return ''
                }
            }
        },
        userId:{
            get(){
              const uID = this.$store.state.user.id;
              if(uID !== undefined){
                return String(uID);
              }else{
                return ''
              }
          }


    },
  },
    methods: {
       refresh(){
        // this.$router.push({name:'item_sitemonethingaddress',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
      },
      changeHandler(val){
      this.dataForm.time = val;
      if(this.dataForm.time != null){
        this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
        this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
      }else{
        this.dataForm.startTime = '';
        this.dataForm.endTime = '';
      }
    },
      search(){
         this.pageIndex = 1 ;
         this.getDataList();
         this.refresh();
      },
      //获取数据列表
      getDataList() {
        this.deal = ''
        this.getType();
      const _this = this;
      if(_this.gridId !== ''){
        var grid = 1
      }
      getList(
        {
          page: this.pageIndex,
          limit: this.pageSize,
          startTime: _this.dataForm.startTime,
          endTime: _this.dataForm.endTime,
          dealState:_this.dataForm.dealState,
          exceptionType:_this.dataForm.exceptionType,
          areaCode:_this.dataForm.regionId ? _this.dataForm.regionId : (_this.gridId !== '' ? _this.gridId : _this.regionId),
          ifGrid:grid
        }
      ).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list
            this.totalPage = data.data.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
        this.refresh()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
        this.refresh()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id,deal) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id,deal)
        })
      },
      //详情

      detailsHandle(id){
        this.detailsVisible = true
        this.$nextTick(() => {
          this.$refs.details.init(id)
        })
      },
      //下达
      transmitHandle(row){
        this.transmitVisible = true
        this.$nextTick(() => {
          this.$refs.transmit.init(row,this.userId,this.regionId)
        })
      },
      //上报
      eportedHandle(row){
        this.reportVisible = true
        this.$nextTick(() => {
          this.$refs.report.init(row,this.userId,this.regionId)
        })
      },
      // 日期转换
    dealdate(date) {
      const dt = new Date(date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    //获取type
      getType(){
        const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
        getType(code).then(({data}) => {
                    this.dataForm.addType = data.data;
        })
      },
    }
  }
</script>
